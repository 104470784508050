import {
  Box,
  Flex,
  Image,
  // Skeleton,
  // forwardRef,
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { motion, isValidMotionProp } from "framer-motion";
import placeholder from "assets/images/placeholder.png";

const createMotionComponent = (Component: any) => motion.create(Component);

export const MotionBox = createMotionComponent(
  forwardRef<HTMLDivElement, any>((props, ref) => {
    const chakraProps = Object.fromEntries(
      // do not pass framer props to DOM element
      Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
    );
    return <Box ref={ref} {...chakraProps} />;
  }),
);

export const MotionFlex = createMotionComponent(
  forwardRef<HTMLDivElement, any>((props, ref) => {
    const chakraProps = Object.fromEntries(
      // do not pass framer props to DOM element
      Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
    );
    return <Flex ref={ref} {...chakraProps} />;
  }),
);

export const MotionImage = createMotionComponent(
  forwardRef<HTMLImageElement, any>((props, ref) => {
    const chakraProps = Object.fromEntries(
      // do not pass framer props to DOM element
      Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
    );
    return <Image ref={ref} fallbackSrc={placeholder} {...chakraProps} />;
  }),
);
