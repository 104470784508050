import * as React from "react";
import { useState, useEffect } from "react";
import { BlurhashCanvas } from "react-blurhash";
import { Image } from "@chakra-ui/react";
import placeholder from "assets/images/placeholder.png";

type LazyImageProps = {
  src: string;
  blurHash: string;
  size?: string;
  width?: number;
  height?: number;
  layout?: string;
  rounded?: string;
};

const LazyImage = (props: LazyImageProps) => {
  const { src, blurHash, width, height, size, /* layout, */ rounded } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = document.createElement("img");
    img.src = src;
    img.onload = () => setLoading(false);
  }, [src]);

  return (
    <>
      {loading ? (
        <BlurhashCanvas
          hash={blurHash}
          width={width}
          height={height}
          punch={1}
          style={{ borderRadius: rounded ? "5px" : "" }}
        />
      ) : (
        <Image
          src={src}
          objectFit="cover"
          alt="cover image"
          width={width}
          height={height}
          sizes={size}
          // layout={layout} #todo
          rounded={rounded}
          fallbackSrc={placeholder}
        />
      )}
    </>
  );
};

export default LazyImage;
