import vitLogo from "assets/images/logos/vit.jpg";
import futurefarmsLogo from "assets/images/logos/futurefarms.png";
import IntelLogo from "assets/images/logos/intel.png";
import hingeHealthLogo from "assets/images/logos/hingehealth.png";

import react from "assets/images/skills/react.png";
import typescript from "assets/images/skills/typescript.png";
import python from "assets/images/skills/python.png";
import postgres from "assets/images/skills/postgresql.png";
import redis from "assets/images/skills/redis.png";
import aws from "assets/images/skills/aws.png";
import cpp from "assets/images/skills/cpp.png";
import docker from "assets/images/skills/docker.png";
import git from "assets/images/skills/git.png";
import githubactions from "assets/images/skills/githubactions.png";
import mongodb from "assets/images/skills/mongodb.png";
import nodejs from "assets/images/skills/nodejs.png";
import redux from "assets/images/skills/redux.png";
import sass from "assets/images/skills/sass.png";
import tailwind from "assets/images/skills/tailwind.png";
import k8s from "assets/images/skills/k8s.png";
import nestJs from "assets/images/skills/nestjs.png";
import ruby from "assets/images/skills/ruby.png";

// companyList for About page
export const companyList = [
  {
    title: "Hinge Health",
    alt: "Hinge Health image",
    role: "Senior Software Engineer - Fullstack",
    skills: ["nestjs", "react", "ruby", "python"],
    period: "May 2023 - Present",
    logo: hingeHealthLogo,
  },
  {
    title: "Intel",
    alt: "Intel image",
    role: "Software Development Engineer",
    skills: ["c++", "python", "backend", "render"],
    period: "Jul 2019 - May 2023",
    logo: IntelLogo,
  },
  {
    title: "Intel",
    alt: "Intel image",
    role: "Graduate Technical Intern",
    skills: ["c++", "python", "backend", "tool development"],
    period: "Jul 2018 - Jul 2019",
    logo: IntelLogo,
  },
  {
    title: "Future Farms",
    alt: "FF Image",
    role: "Fullstack Engineering Intern",
    skills: ["javascript", "react", "nodejs", "mongodb"],
    period: "Oct 2016 - Jan 2017",
    logo: futurefarmsLogo,
  },
];

export const companies = [
  {
    title: "Hinge Health",
    alt: "Hinge Health image",
    role: "Senior Software Engineer - Fullstack",
    skills: ["nestjs", "react", "ruby", "python"],
    period: "Mar 2023 - Present",
    logo: hingeHealthLogo,
  },
  {
    title: "Hinge Health",
    alt: "Hinge Health image",
    role: "Software Engineer II - Fullstack",
    skills: ["nestjs", "react", "ruby", "python"],
    period: "May 2023 - Mar 2023",
    logo: hingeHealthLogo,
  },
  {
    title: "Intel",
    alt: "Intel image",
    role: "Software Development Engineer",
    skills: ["c++", "python", "backend", "render"],
    period: "Jul 2019 - May 2023",
    logo: IntelLogo,
  },
  // {
  //   title: "Intel",
  //   alt: "Intel image",
  //   role: "Graduate Technical Intern",
  //   skills: ["c++", "python", "backend", "tool development"],
  //   period: "2018 - 2019",
  //   logo: IntelLogo,
  // },
  // {
  //   title: "Future Farms",
  //   alt: "FF Image",
  //   role: "Fullstack Engineering Intern",
  //   skills: ["javascript", "react", "nodejs", "mongodb"],
  //   period: "2016 - 2017",
  //   logo: futurefarmsLogo,
  // },
];

export const institutes = [
  {
    short_title: "VIT University",
    title: "VIT University, Vellore",
    alt: "VIT image",
    role: "Master of Technology in Software Engineering",
    skills: [
      "data structures",
      "algorithms",
      "programming",
      "web development",
      "sdlc",
    ],
    period: "Jun 2014 - Jun 2019",
    startingYear: "2014",
    logo: vitLogo,
    awards: [
      // {
      //   title: "Speed Programming Battle",
      //   description:
      //     "Won speed programming battle which held at FAST - NUCES.",
      //   date: "May 1, 2015"
      // },
      // {
      //   title: "Silver Medal",
      //   description:
      //     "This medal was awarded to me by FAST - NUCES for getting a CGPA of 3.9 in 2nd semester.",
      //   date: "May 1, 2014"
      // },
      // {
      //   title: "Speed Programming Battle",
      //   description:
      //     "Won speed programming battle which held at FAST - NUCES.",
      //   date: "Nov 1, 2013"
      // }
    ],
    internships: [
      {
        title: "Intel",
        alt: "Intel image",
        role: "Graduate Technical Intern",
        skills: ["c++", "python", "backend", "tool development"],
        period: "Jul 2018 - Jul 2019",
        logo: IntelLogo,
      },
      {
        title: "Future Farms",
        alt: "FF Image",
        role: "Fullstack Engineering Intern",
        skills: ["javascript", "react", "nodejs", "mongodb"],
        period: " Oct 2016 - Jan 2017",
        logo: futurefarmsLogo,
      },
    ],
  },
  // {
  //   short_title: "Govt Degree College",
  //   title: "Govt Degree College",
  //   alt: "college image",
  //   role: "FSC",
  //   skills: ["mathematic", "chemistry", "physics"],
  //   period: "2011 - 2013",
  //   startingYear: "2011",
  //   logo: ""
  // }
];

export const skills = [
  {
    name: "C++",
    description: "Programming",
    link: "https://isocpp.org/",
    type: "backend",
    image: cpp,
  },
  {
    name: "Python",
    description: "Programming",
    link: "https://www.python.org/",
    type: "backend",
    image: python,
  },
  {
    name: "Typescript",
    description: "Web",
    link: "https://www.typescriptlang.org/",
    type: "frontend",
    image: typescript,
  },
  {
    name: "React",
    description: "Frontend Lib",
    link: "https://reactjs.org/",
    type: "frontend",
    image: react,
  },
  {
    name: "Redux",
    description: "State management",
    link: "https://redux.js.org/",
    type: "frontend",
    image: redux,
  },
  {
    name: "Sass",
    description: "Styling",
    link: "https://sass-lang.com/",
    type: "frontend",
    image: sass,
  },
  {
    name: "Tailwind",
    description: "Styling",
    link: "https://tailwindcss.com/",
    type: "frontend",
    image: tailwind,
  },
  {
    name: "NodeJs",
    description: "Backend",
    link: "https://nodejs.org/en",
    type: "backend",
    image: nodejs,
  },
  {
    name: "NestJs",
    description: "Backend",
    link: "https://nestjs.com/",
    type: "backend",
    image: nestJs,
  },
  {
    name: "Ruby",
    description: "Backend",
    link: "https://www.ruby-lang.org/en/",
    type: "backend",
    image: ruby,
  },
  {
    name: "MongoDB",
    description: "Database",
    link: "https://www.mongodb.com/",
    type: "backend",
    image: mongodb,
  },
  {
    name: "PostgreSQL",
    description: "Database",
    link: "https://www.postgresql.org/",
    type: "backend",
    image: postgres,
  },
  {
    name: "Redis",
    description: "Database",
    link: "https://redis.io/",
    type: "backend",
    image: redis,
  },
  {
    name: "Git",
    description: "SCM",
    link: "https://git-scm.com/",
    type: "devops",
    image: git,
  },
  {
    name: "Github Actions",
    description: "DevOps",
    link: "https://github.com/features/actions",
    type: "devops",
    image: githubactions,
  },
  {
    name: "AWS",
    description: "Cloud",
    link: "https://aws.amazon.com/",
    type: "devops",
    image: aws,
  },
  {
    name: "Docker",
    description: "Containerization",
    link: "https://www.docker.com/",
    type: "devops",
    image: docker,
  },
  {
    name: "Kubernetes",
    description: "Container orchestration",
    link: "https://kubernetes.io/",
    type: "devops",
    image: k8s,
  },
];
