import * as React from "react";
import { useState, useEffect } from "react";
import { Stack, useColorModeValue, Text } from "@chakra-ui/react";
// import { articles } from "data/posts/articles";
import PostCard from "./post-card";
import { PageSlideFade, StaggerChildren } from "components/page-transitions";
import Header from "components/header";
import { MotionBox } from "components/motion";

// import { HashnodeArticle } from "./hashnode-models";
// import { HashnodeClient } from "./hashnode-client";
// import { DevToArticle } from "./hashnode-models";

const TURQUOISE = "#06b6d4";

const Posts = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    // async function func(): Promise<void> {
    //   const client = new HashnodeClient();
    //   const result = await client.fetchBlogPosts();
    //   const articles = result;
    //   console.log(articles);

    //   setArticles(articles);
    // }
    // func();

    const getPosts = async () => {
      try {
        const response = await fetch(
          "https://dev.to/api/articles?username=pvishnuprasaath",
        );

        const json = await response.json();
        // console.log(json);
        let result = [];
        json.forEach((data) => {
          result.push({
            title: data.title,
            description: data.description,
            canonical_url: data.canonical_url,
            published_at: data.published_at,
            reading_time_minutes: data.reading_time_minutes,
            tag_list: data.tag_list,
          });
        });
        setArticles(result);
      } catch (error) {
        console.log("error", error);
      }
    };
    getPosts();
  }, []); // [] means only load once on initial page render
  return (
    <PageSlideFade>
      <Header underlineColor={TURQUOISE} mt={0} mb={0}>
        Article dump
      </Header>
      {/* <br /> */}
      <StaggerChildren>
        <Stack spacing={4} mt={12}>
          {articles.map((article, index) => (
            <MotionBox whileHover={{ y: -5 }} key={index}>
              <PostCard article={article} />
            </MotionBox>
          ))}
        </Stack>
        <br />
        <Text
          color={useColorModeValue("gray.500", "gray.200")}
          textAlign="left"
        >
          Typing more &#128583; !!
          <br />
          &#129302; Personal Downtime :P
          <br />
          Revisit in 2 days &#8987; to see the same list
        </Text>
      </StaggerChildren>
    </PageSlideFade>
  );
};

export default Posts;
